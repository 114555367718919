import * as React from 'react';

const SvgLogo = (props) => (
  <svg
    width={268.154}
    height={196.616}
    viewBox="0 0 70.949 52.021"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      style={{
        fill: '#fff',
        strokeWidth: 0.115566
      }}
      className="logo_svg__st0"
      d="M112.295 107.69a.42.42 0 0 0-.402.27l-9.097 23.16c7.522 8.264 19.435 5.351 30.237 5.265a16.214 16.214 0 0 1-.086-2.773 63.08 63.08 0 0 0-.53.015c.183-.092.365-.19.546-.288v-.012a16.557 16.557 0 0 1 1.58-6.02 16.743 16.743 0 0 1 3.915-5.216 16.99 16.99 0 0 1 3.364-2.325c-.17-1.836-.957-3.353-2.278-4.287 4.518-.143 8.893.072 12.739 1.643-.09.316-.14.633-.166.95 2.274.364 4.471 1.2 6.405 2.453l.804-2.045c-11.232-12.338-32.253.244-44.748-8.877-.862-.63-2.003-1.804-2.003-1.804a.425.425 0 0 0-.28-.109zm4.668 6.64c3.846 1.572 8.226 1.788 12.745 1.644-3.765 1.885-7.08 5.12-8.615 9.028-1.533 3.904-.883 7.45 1.482 9.12-4.518.144-8.894-.071-12.74-1.644.606-2.14-.322-4.364-1.983-6.026l3.965-10.09c1.925.986 4.146-.083 5.146-2.031zm17.353 4.56c-.148.383-.3.765-.45 1.148.432.01.833.047 1.192.116.61.117 1.129.552 1.129.552.072.037.105.105.087.189a.41.41 0 0 1-.177.232l-1.199.815c-.126.088-.282.112-.383.068 0 0-.407-.296-.827-.368a6.063 6.063 0 0 0-1.392-.065c-.663.04-1.198.173-1.6.408-.401.233-.672.523-.807.87-.123.314-.105.545.057.699.161.152.5.24 1.017.255l1.463.021c1.085.017 1.825.228 2.23.618.408.39.451.991.135 1.795-.188.478-.464.899-.828 1.267a5.321 5.321 0 0 1-1.284.948 7.028 7.028 0 0 1-1.633.624 8.93 8.93 0 0 1-.793.158l-.534 1.356c-.637.038-1.275.078-1.914.114.173-.442.347-.885.522-1.327a8.243 8.243 0 0 1-1.465-.14c-.679-.133-1.38-.794-1.38-.794-.066-.038-.093-.11-.073-.191a.436.436 0 0 1 .176-.224c.425-.282.85-.567 1.274-.854.133-.087.3-.112.399-.054 0 0 .511.49 1.002.585a6.528 6.528 0 0 0 1.55.094c.678-.042 1.281-.176 1.803-.405.525-.228.873-.558 1.042-.988.12-.31.109-.55-.04-.718-.148-.17-.476-.258-.978-.28-.556-.005-1.113-.014-1.669-.022-.986-.02-1.676-.22-2.067-.587-.396-.368-.432-.956-.118-1.758.174-.443.435-.844.78-1.2a5.314 5.314 0 0 1 1.21-.917 6.674 6.674 0 0 1 1.495-.608c.22-.062.45-.107.677-.15l.457-1.168c.64-.037 1.277-.076 1.914-.114z"
      transform="translate(-102.796 -107.69)"
    />
    <path
      style={{
        fill: '#fff',
        strokeWidth: 0.115566
      }}
      className="logo_svg__st0"
      d="m160.584 121.967-.037.095c.055.048.11.096.163.145zm-55.556 14.583-.972 2.08c1.976 1.38 4.084 2.308 6.19 2.915 3.324.957 6.645 1.143 9.53 1.143 2.445 0 4.93-.148 7.31-.29 2.314-.138 4.681-.28 6.898-.279l.034-.002.4-.002c.128 0 .253.003.38.005a16.513 16.513 0 0 1-.84-1.877c-.046-.123-.084-.248-.127-.372-2.262.007-4.616.147-6.879.282-2.38.143-4.82.287-7.176.286-2.786.002-5.893-.186-8.907-1.057-2.028-.587-4.003-1.482-5.841-2.831z"
      transform="translate(-102.796 -107.69)"
    />
    <path
      d="m26.457 27.262-.709.71a2.006 2.006 0 0 1-2.838 0l-3.522-3.521c-.656-.658-.729-1.644-.281-2.412l-2.559-2.557a9.975 9.975 0 0 1-5.965 1.979C5.043 21.461.552 16.97.552 11.43c0-5.54 4.491-10.032 10.031-10.032 5.541 0 10.031 4.491 10.031 10.032 0 2.579-.98 4.923-2.58 6.7l2.49 2.49c.768-.447 1.754-.375 2.41.283l3.522 3.52c.784.785.784 2.056.001 2.84zM18.088 11.39a7.524 7.524 0 0 0-7.524-7.523 7.523 7.523 0 1 0-.001 15.046 7.525 7.525 0 0 0 7.525-7.523z"
      style={{
        fill: '#fff'
      }}
      transform="matrix(1.4704 0 0 1.4704 31.183 10.028)"
    />
    <path
      style={{
        color: '#000',
        fill: '#fff',
        strokeLinecap: 'round',
        InkscapeStroke: 'none'
      }}
      d="M344.713 117.035c-9.981 0-18.114 8.136-18.113 18.117a1.984 1.984 0 0 0 1.984 1.985 1.984 1.984 0 0 0 1.984-1.987 14.115 14.115 0 0 1 14.145-14.146 1.984 1.984 0 0 0 1.984-1.984 1.984 1.984 0 0 0-1.984-1.985z"
      transform="matrix(.511 0 0 .511 -129.428 -42.29)"
    />
  </svg>
);
export default SvgLogo;
